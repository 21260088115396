import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import ImgLoader from "../../utils/ImgLoader";
import ArabicHeader from "../Header/ArabicHeader";
import { useRouter } from "next/router";
import JobHeader from "../Header/JobHeader";
import Head from "next/head";

const Error404 = () => {
  const router = useRouter()

  const [locale, setLocale] = useState('ar')

  useEffect(()=>{
      if(router.asPath){
        setLocale(router.asPath.split('/')[1] == 'en' ? 'en' : 'ar')
      }
  },[router])

  return (
    <div style={{paddingTop: '100px'}}>
      <Head>
        <title>Page Not Found - Skatch | Find jobs</title>
      </Head>
      {locale == 'en' ? <JobHeader dynamicSlug={"/"} /> : <ArabicHeader dynamicSlug={"en" + router?.route} />}
      <div
        className={"row justify-content-center"}
        style={{
          width: "100%",
          margin: "0px",
          padding: "15px",
          background: "transprent",
        }}
      >
        <div
          className="col-md-6 col-12"
          style={{
            textAlign: "center",
            position: "relative",
            height: "auto",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src="/images/error/404.svg"
            alt="404 not found"
            loader={ImgLoader}
            className="img-fluid"
            width={400}
            height={400}
            style={{ width: "100%", height: "auto", margin: "25px 0px" }}
          />
          <a href={locale == 'en'? "/en" : "/"} passHref 
          style={{
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "600",
            color: "#fff",
            background: "#D56C33",
            padding: "10px 15px",
            margin: "15px",
            borderRadius: '3px',
          }}
          >
              Go to Home Page
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error404;
