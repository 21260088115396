import React from "react";
import Link from "next/link";
function JobHeader(props) {
  const { dynamicSlug } = props;
  const HR_PORTAL_ENGLISH_URL = `https://hr.skatch.com/en`;
  return (
    <>
      <div className="theme-main-menu sticky-menu theme-menu-five job-menu">
        <div className="">
          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="" style={{ cursor: "pointer" }}>
              <Link
                href="/"
                className="cursor navbar-brand"
                style={{ cursor: "pointer" }}
              >
                <img
                  src="/images/job/ab-logo.svg"
                  alt="skatch"
                  width="163"
                  height="40"
                />
              </Link>
            </div>
            <button
              role="button"
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarColor02"
              aria-controls="navbarColor02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {" "}
              <span className="navbar-toggler-icon"></span>{" "}
            </button>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav ml-auto main-side-nav font-gordita"
                id="one-page-nav"
              >
                <li className="nav-item">
                  <a href="#job-work" className="nav-link">
                    How it Works
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={dynamicSlug}>
                    العربية
                  </a>
                </li>
              </ul>
              <div className="right-widget">
                <a href={HR_PORTAL_ENGLISH_URL} className="want-button">
                  <span>Want to hire</span>
                </a>
                <a
                  href={process.env.APP_LINK}
                  target="_blank"
                  className="hire-button"
                  style={{ backgroundColor: "#d56c33", color: "#fff" }}
                >
                  <span>Download Now</span>
                  <img
                    src="/images/icon/119.svg"
                    alt="get-image"
                    width="28"
                    height="15"
                  />
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
export default JobHeader;
