import React from 'react'
import Error404 from '../Components/Layout/Error404'

const Custom404 = () => {
    
  return (
    <Error404 />
  )
}

export default Custom404